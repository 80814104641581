import { Col, Flex, Image, Layout, Row, Space, Typography } from "antd"
import Logo from "../../assets/images/230x0w.webp"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"

export const Home = () => {
    return (
        <div style={{ height: window.innerHeight }}>
            <MainLayout menuType={MenuType.Home}>
                <Layout.Content className="home">
                    <Row gutter={[16, 16]} className="home-service" style={{ marginBottom: 260, }}>
                        <Col span={24}>
                            <Flex justify="center">
                                <Space direction="vertical">
                                    <Image
                                        preview={false}
                                        src={Logo}
                                    />
                                </Space>
                            </Flex>
                        </Col>
                        <Col span={24} style={{ marginTop: 32, }}>
                            <Flex justify="center">
                                <Space direction="vertical">
                                    <Typography.Title level={1} style={{ fontSize: 36, textAlign: 'center' }}>
                                        맘마플래너
                                    </Typography.Title>
                                </Space>
                            </Flex>
                        </Col>
                        {/* <Col span={24} style={{ marginTop: 32, }}>
                            <Flex justify="center">
                                <Space direction="vertical" style={{ textAlign: 'center' }}>
                                    <Typography.Title level={1} style={{ fontSize: 36, textAlign: 'center', color: '#ffc803' }}>
                                        {'Momma-Planner, Confident Parenting.'}
                                    </Typography.Title>
                                    <Typography.Text style={{ whiteSpace: 'pre-wrap', textAlign: 'center', fontSize: 16, color: '#999' }}>
                                        {'육아에서 가장 힘든 순간은 ‘왜’라는 질문에 대한 명확한 답을 찾을 수 없을 때 입니다.\n 맘마플래너는 아기의 다양한 활동을 기록하고 분석해 정확한 상황과 정보를 제공하는 서비스입니다.\n각각의 아기 생활 패턴에 기반한 맞춤형 정보는 여러분과 아기 모두를 건강하게 만들 것입니다.'}
                                    </Typography.Text>
                                </Space>
                            </Flex>
                        </Col> */}
                        <Col span={24} style={{ marginTop: 32, }}>
                            <Flex justify="center">
                                <Space size={24} style={{ textAlign: 'center' }}>
                                    <Typography.Link
                                        target="_blank"
                                        href="https://apps.apple.com/kr/app/%EB%A7%98%EB%A7%88%ED%94%8C%EB%9E%98%EB%84%88/id6443713917"
                                    >
                                        <Image
                                            preview={false}
                                            src="https://www.babytime.care/wp-content/uploads/2016/06/icon-app-store@3x-246x80.png"
                                            style={{ width: 160, }}
                                        />
                                    </Typography.Link>
                                    <Typography.Link
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.appg.mommaplanner&hl=ko"
                                    >
                                        <Image
                                            preview={false}
                                            src="https://www.babytime.care/wp-content/uploads/2016/06/icon-play-store@3x-247x80.png"
                                            style={{ width: 160, }}
                                        />
                                    </Typography.Link>
                                </Space>
                            </Flex>
                        </Col>
                    </Row>
                    {/* <Divider
                        style={{
                            borderWidth: 2,
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(#F8B31A, #fff)'
                        }}
                    />
                    <HomeService />
                    <Divider
                        style={{
                            borderWidth: 2,
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(to right, #F8B31A, #fff)'
                        }}
                    />
                    <HomeOrganization />
                    <Divider
                        style={{
                            borderWidth: 2,
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(to left, #F8B31A, #fff)'
                        }}
                    />
                    <HomePartner /> */}
                </Layout.Content>
            </MainLayout>
        </div>
    )
}