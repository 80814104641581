import { Layout } from "antd"
import { FunctionComponent, ReactNode, useEffect } from "react"
import { MenuType } from "../type/MenuType"
import { Footer } from "./Footer"
import { Navigation } from "./Navigation"

interface IProps {
    menuType?: MenuType
    children?: ReactNode
}

export const MainLayout: FunctionComponent<IProps> = (props: IProps) => {
    const { children } = props

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <Layout.Content>
            {/* <Navigation /> */}
            {children}
            <Footer />
        </Layout.Content>
    )
}