import { LoadingOutlined } from '@ant-design/icons'
import { Col, message, Row, Space, Spin, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import '../../styles/notice.scss'
import { NoticeModel } from '../../model/NoticeModel'
import DatetimeUtility from '../../util/DatetimeUtility'
import { NoticeService } from '../../service/NoticeService'
import { Errors } from '../../contexts/ErrorContext'
const { Title, Text } = Typography

export const Notice = () => {
    let { id } = useParams()
    const navigate = useNavigate()

    if (!id) {
        message.error("공지사항 상세정보를 찾을 수 없습니다.", 2, () => { navigate(-1) })
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [notice, setNotice] = useState<NoticeModel.INoticeModel | null>(null)

    const getNotice = async () => {
        setLoading(true)
        const response = await NoticeService.notice(id!)
        if (response.status === 200) {
            setNotice(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getNotice()
    }, [])

    return (
        <Row gutter={[0, 0]} justify={"center"} align={"middle"} className={"notice-container"} style={{ marginTop: "2em" }}>
            {(isLoading && !notice) && (
                <Spin
                    spinning
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    style={{ marginTop: 50 }}
                />
            )}
            <Col span={24} style={{ padding: "0px 16px" }}>
                {notice && (
                    <Row gutter={[8, 8]} style={{ backgroundColor: "#fff" }}>
                        <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
                            <Space direction={"vertical"}>
                                <Title level={4}>{notice.title}</Title>
                                <Text>{DatetimeUtility.toKorean(notice.created)}</Text>
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }} style={{ marginTop: 20 }}>
                            <div className="rdw-editor-main" dangerouslySetInnerHTML={{ __html: notice.html }}></div>
                        </Col>
                    </Row>
                )
                }
            </Col >
        </Row >
    )
}