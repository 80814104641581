import { Col, Flex, Layout, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"

export const Footer: FunctionComponent = () => {
    return (
        <footer className="footer">
            <Layout.Content style={{ maxWidth: 1200, margin: '0 auto' }}>
                <Row justify="end">
                    <Col {...{ xs: 0, md: 0, lg: 24 }}>
                        <Row gutter={[24, 24]}>
                            {/* <Col span={24}>
                                <Row align={"middle"}>
                                    <Col span={12}>
                                        <Space size={4} direction="vertical">
                                            <Typography.Link
                                                href="tel: 0212341234"
                                            >
                                                <Typography.Title level={5}>
                                                    +82 02.123.4567
                                                </Typography.Title>
                                            </Typography.Link>
                                            <Typography.Text>
                                                <Typography.Title level={5}>
                                                    help@mommaplaner.com
                                                </Typography.Title>
                                            </Typography.Text>
                                            <Typography.Text>서울특별시 강남구 테헤란로 1길, 맘마플래너타워 10층</Typography.Text>
                                        </Space>
                                    </Col>
                                    <Col span={12} className="sns">
                                        <Flex justify="end" align="center">
                                            <Space>
                                                <Typography.Link href="https://www.facebook.com/" target="_blank">
                                                    <Facebook />
                                                </Typography.Link>
                                                <Typography.Link href="https://blog.naver.com/" target="_blank">
                                                    <Naver />
                                                </Typography.Link>
                                            </Space>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col> */}
                            <Col span={24}>
                                <Flex justify="center">
                                    <Space direction="vertical">
                                        <Flex justify="center">
                                            <Space>
                                                <Typography.Link
                                                    href="/privacy-terms"
                                                >
                                                    개인정보처리방침
                                                </Typography.Link>
                                                <Typography.Link
                                                    href="/service-terms"
                                                >
                                                    서비스 약관
                                                </Typography.Link>
                                            </Space>
                                        </Flex>
                                        <Typography.Text>copyright © Mommaplanner. All rights reserved.</Typography.Text>
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...{ xs: 24, sm: 24, md: 0, lg: 0, xl: 0, xxl: 0 }}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Space size={4} direction="vertical">
                                    <Typography.Text>
                                        <Typography.Link
                                            href="tel: 021231234"
                                        >
                                            +82 02.123.1234
                                        </Typography.Link>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Text>
                                            help@mommaplaner.com
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text>서울특별시 강남구 테헤란로 1길, 맘마플래너타워 10층</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Typography.Text>copyright © Mommaplanner. All rights reserved.</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </footer>
    )
}