import dayjs from 'dayjs'

export default class DatetimeUtility {
    static toShortDateString(date: string, format: string = "YYYY-MM-DD"): string {
        return dayjs(date).format(format)
    }

    static toDetailDateTimeString(time: number | string, display: string = "-"): string {
        if(!time) return display

        if(typeof time === 'number' && time > 1000000000000) {
            return dayjs.unix(time).format("YYYY-MM-DD HH:mm:ss")
        } else {
            return dayjs(time).format("YYYY-MM-DD HH:mm:ss")
        }
    }

    static toKorean(date?: string, format: string = "YYYY년 M월 D일 HH:mm:ss"): string {
        return date ? dayjs(date).format(format) : dayjs().format(format)
    }

    static toKoreanDetail(date: string, format: string = "YYYY년 M월 D일 H 시 m분 s초"): string {
        return dayjs(date).format(format)
    }

    static to(date: string, format: string = "YYYY-MM-DDTHH:mm:ss"): string {
        return dayjs(date).format(format)
    }

    static detail(date: string, format: string = "YYYY-MM-DD HH:mm:ss"): string {
        return dayjs(date).format(format)
    }
}