import { Col, Divider, Layout, Row, Space, Typography } from "antd"
const { Text, Paragraph, Title, } = Typography

export const MarketingTerm = () => {
    return (
        <Layout.Content
            style={{
                maxWidth: 870,
                margin: '0 auto'
            }}
        >
            <Row
                justify={'center'}
                style={{ padding: 16 }}
            >
                <Col xs={{ span: 24 }} style={{ backgroundColor: '#fff', marginBottom: 30, }}>
                    <Layout.Content style={{ backgroundColor: '#fff', textAlign: 'center', marginTop: 12, }}>
                        <Title level={3}>(선택) 마케팅 정보 수신 동의</Title>
                        <Divider />
                        {/* <Title level={5} style={{ color: '#999' }}>본 약관은 2023-03-21부터 적용됩니다.</Title> */}
                    </Layout.Content>
                    <Space direction="vertical" size="large">
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Text style={{ fontSize: 16 }}>
                                        맘마플래너는 "정보통신망 이용촉진 및 정보보호에 관한 법률", “개인정보보호법” 등 관계 법령에 따라 광고성 정보를 전송하기 위해 수신자의 사전 수신 동의를 받고 있으며,
                                        광고성 정보 수신자의 수신 동의 여부를 정기적으로 확인합니다. 다만 동의하지 않을 경우, 이벤트 등 이용목적에 따른 혜택에 제한이 있을 수 있습니다.
                                    </Text>
                                </Paragraph>
                            </Layout.Content>
                            <Divider />
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical" size={4}>
                                        <Text strong style={{ fontSize: 16 }}>
                                            이용 목적
                                        </Text>
                                        <ul>
                                            <li style={{ fontSize: 16 }}>앱푸시 및 문자 메세지, 알림톡을 통한 광고성 정보 전송</li>
                                            <li style={{ fontSize: 16 }}>맘마플래너 혜택, 이벤트, 광고 등 마케팅에 활용</li>
                                            <li style={{ fontSize: 16 }}>맘마플래너 서비스 이용에 따른 정보성 안내는 수신 동의 여부와 무관하게 제공됩니다.</li>
                                        </ul>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical" size={4}>
                                        <Text strong style={{ fontSize: 16 }}>
                                            이용항목
                                        </Text>
                                        <ul>
                                            <li style={{ fontSize: 16 }}>이메일</li>
                                        </ul>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff', }}>
                                <Paragraph>
                                    <Space direction="vertical" size={4}>
                                        <Text strong style={{ fontSize: 16 }}>
                                            보유 및 이용기간
                                        </Text>
                                        <ul>
                                            <li style={{ fontSize: 16 }}>마케팅 활용 동의일로부터 맘마플래너 탈퇴 또는 동의철회 시까지</li>
                                        </ul>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Divider />
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical" size={4}>
                                        <Text type={'secondary'} style={{ fontSize: 16 }}>본 동의는 선택 사항으로 거부하실 수 있으며, 서비스 이용에 제한이 없습니다.</Text>
                                        <Text type={'secondary'} style={{ fontSize: 16 }}>마케팅 정보 수신 동의 및 해제는 앱의 설정화면에서 언제든 변경할 수 있습니다.</Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                    </Space>
                </Col>
            </Row>
        </Layout.Content>
    )
}
