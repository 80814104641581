import { Col, Divider, Layout, Row, Space, Typography } from "antd"
const { Text, Paragraph, Title, } = Typography

export const PrivacyTerm = () => {
    return (
        <Layout.Content
            style={{
                maxWidth: 1100,
                margin: '0 auto'
            }}
        >
            <Row
                justify={'center'}
                style={{ padding: 16 }}
            >
                <Col xs={{ span: 24 }} style={{ backgroundColor: '#fff', marginBottom: 30, }}>
                    <Layout.Content style={{ backgroundColor: '#fff', textAlign: 'center', marginTop: 12, }}>
                        <Title level={3}>개인정보 처리방침</Title>
                        <Divider />
                        {/* <Title level={5} style={{ color: '#999' }}>본 약관은 2023-03-21부터 적용됩니다.</Title> */}
                        <Paragraph style={{ textAlign: 'left' }}>
                            <Text>
                                맘마플래너(이하 ‘회사’)는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                            </Text>
                        </Paragraph>
                    </Layout.Content>
                    <Space direction="vertical" size="large">
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제1장 총칙</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제1조(개인정보의 처리 목적)
                                        </Text>
                                        <Text>
                                            회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 각호의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                                <Paragraph style={{ paddingLeft: 16, }}>
                                    <Space direction="vertical" wrap>
                                        <Text>1. 회원가입 및 관리 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 민원처리, 회원탈퇴 의사 확인, 각종 고지·통지 목적 등으로 개인정보를 처리합니다.</Text>
                                        <Text>2. 서비스 제공 : 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.</Text>
                                        <Text>3. 신규서비스 개발 및 마케팅, 광고에의 활용 : 신규 서비스(제품) 개발, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 등을 목적으로 개인정보를 처리합니다.</Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제2조(개인정보의 보유 및 이용기간)
                                        </Text>
                                        <Text>
                                            원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기하나, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 다음 각호의 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                                <Paragraph style={{ paddingLeft: 16, }}>
                                    <Space direction="vertical" wrap>
                                        <Text>1. 표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)</Text>
                                        <Text>2. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</Text>
                                        <Text>3. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</Text>
                                        <Text>4. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</Text>
                                        <Text>5. 서비스 이용 기록, 접속 로그, 접속 IP 정보: 3개월 (통신비밀보호법)</Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제3조(개인정보의 파기절차 및 파기방법)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</Text>
                                                <Text>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</Text>
                                                <Text>③ 개인정보 파기의 절차 및 방법은 다음 각호와 같습니다.</Text>
                                                <Paragraph style={{ paddingLeft: 16, }}>
                                                    <Space direction="vertical" wrap size={2}>
                                                        <Text>1. 파기절차 : 회원가입 등을 위해 회원이 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유된 목적 이외의 목적으로 이용 되지 않습니다</Text>
                                                        <Text>2. 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</Text>
                                                    </Space>
                                                </Paragraph>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제4조(개인정보의 제3자 제공)
                                        </Text>
                                        <Text>회사는 회원의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 회원의 개인정보를 제 3자에게 제공하지 않습니다.</Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제5조(개인정보의 위탁 처리)
                                        </Text>
                                        <Text>
                                            회사는 원활한 서비스 제공 및 회원의 편의 증진 등을 위해 국외 사업자(AWS, Amazon Web Service Inc.)에게 개인정보를 위탁 보관하고 있으며, 회원으로부터 취득 또는 생성한 개인정보를 AWS가 보유하고 있는 데이터베이스(물리적 저장 장소 : 서울)에 저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하며, 회원의 개인정보에 접근할 수 없습니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>제2장 서비스 이용</Title>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap >
                                                <Text>① 정보주체는 법령에 저촉되지 아니하는 범위 내에서 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</Text>
                                                <Text>② 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 전자우편 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</Text>
                                                <Text>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</Text>
                                                <Text>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</Text>
                                                <Text>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</Text>
                                                <Text>⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)
                                        </Text>
                                        <Text>
                                            서비스 이용 과정에서 IP 주소, 쿠키가 자동으로 수집될 수 있으나 이와 같이 수집된 정보는 개인정보와 연계되지 않으며, 회원은 로그아웃이나 앱 및 데이터 삭제 등을 통해 언제든지 삭제할 수 있습니다.
                                        </Text>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제8조(추가적인 이용·제공 판단기준)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>
                                                    ① 회사는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.
                                                    이에 따라 회사는 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음 각호의 사항을 고려해야 합니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical">
                                                            <Text>1. 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</Text>
                                                            <Text>2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부</Text>
                                                            <Text>3. 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부</Text>
                                                            <Text>4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</Text>
                                                        </Space>
                                                    </Paragraph>
                                                    <Text type="secondary">
                                                        ※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 회사 스스로 자율적으로 판단하여 작성·공개함
                                                    </Text>
                                                </Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제9조 (개인정보 보호책임자에 관한 사항)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical" wrap>
                                                <Text>
                                                    ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 다음과 같이 개인정보 보호책임자를 지정하고 있습니다.
                                                    <Paragraph style={{ paddingLeft: 16, }}>
                                                        <Space direction="vertical" wrap size={2}>
                                                            <Text>성명 : 오소정</Text>
                                                            <Text>소속 : 맘마플래너</Text>
                                                            <Text>직급 : 대표</Text>
                                                            <Text>E-mail : mommaplanner@gmail.com</Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Text>
                                                <Text>② 정보주체는 회사의 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자로 문의할 수 있으며, 회사는 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</Text>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                            <Layout.Content style={{ backgroundColor: '#fff' }}>
                                <Paragraph>
                                    <Space direction="vertical">
                                        <Text strong>
                                            제10조(정보주체의 권익침해에 대한 구제방법)
                                        </Text>
                                        <Paragraph style={{ paddingLeft: 16, }}>
                                            <Space direction="vertical">
                                                <Text>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 다음의 기관에 문의하시기 바랍니다.</Text>
                                                <Space direction="vertical">
                                                    <Space size={2} wrap>
                                                        <Text>1. 개인정보분쟁조정위원회 : </Text>
                                                        <Typography.Link href="tel:1833-6972" target="_blank">
                                                            (국번없이) 1833-6972
                                                        </Typography.Link>
                                                        <Typography.Link href="www.kopico.go.kr" target="_blank">
                                                            (www.kopico.go.kr)
                                                        </Typography.Link>
                                                    </Space>
                                                    <Space size={2} wrap>
                                                        <Text>2. 개인정보침해신고센터 : </Text>
                                                        <Typography.Link href="tel:118" target="_blank">
                                                            (국번없이) 118
                                                        </Typography.Link>
                                                        <Typography.Link href="privacy.kisa.or.kr" target="_blank">
                                                            (privacy.kisa.or.kr)
                                                        </Typography.Link>
                                                    </Space>
                                                    <Space size={2} wrap>
                                                        <Text>3. 대검찰청 사이버수사과 : </Text>
                                                        <Typography.Link href="tel:1301" target="_blank">
                                                            (국번없이) 1301
                                                        </Typography.Link>
                                                        <Typography.Link href="www.spo.go.kr" target="_blank">
                                                            (www.spo.go.kr)
                                                        </Typography.Link>
                                                    </Space>
                                                    <Space size={2} wrap>
                                                        <Text>4. 경찰청 사이버안전국 : </Text>
                                                        <Typography.Link href="tel:182" target="_blank">
                                                            (국번없이) 182
                                                        </Typography.Link>
                                                        <Typography.Link href="ecrm.cyber.go.kr" target="_blank">
                                                            (ecrm.cyber.go.kr)
                                                        </Typography.Link>
                                                    </Space>
                                                </Space>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Paragraph>
                            </Layout.Content>
                        </Layout.Content>
                    </Space>
                </Col>
            </Row>
        </Layout.Content>
    )
}