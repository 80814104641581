import axios, { AxiosError, AxiosRequestConfig } from "axios";

const instance = axios.create({
    timeout: 60000,
    baseURL: 'https://api.mommaplanner.com/api',
    withCredentials: false,
    responseType: "json",
    headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json; charset:UTF-8'
    },
} as AxiosRequestConfig);

instance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response) {
        const { status, data, config } = error.response;
        if (status === 401) {
            console.log("401", data, config);
        }

        if (status === 403) {
            console.log("403", data, config);
        }

        if (status === 500) {
            console.log("500", data, config);
        }
    }

    return error.response;
});

export default instance;